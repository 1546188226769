import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Typography,
	IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';

import { Button } from '../Button';

type OpenDialogOptions = {
	cancelNavigation: (shouldSubmit?: boolean) => void;
	confirmNavigation: () => void;
};

type FormExitDialogContextValue = {
	openDialog: (options: OpenDialogOptions) => void;
	closeDialog: () => void;
};

export const FormExitDialogContext = React.createContext<FormExitDialogContextValue>(
	{} as FormExitDialogContextValue
);

export const FormExitDialogProvider: React.FC = ({ children }) => {
	const [confirmNavigation, setConfirmedNavigation] =
		useState<null | OpenDialogOptions['confirmNavigation']>(null);
	const [cancelNavigation, setCancelNavigation] =
		useState<null | OpenDialogOptions['cancelNavigation']>(null);

	const openDialog = ({ cancelNavigation, confirmNavigation }: OpenDialogOptions) => {
		setConfirmedNavigation(() => confirmNavigation);
		setCancelNavigation(() => cancelNavigation);
	};

	const closeDialog = () => {
		setConfirmedNavigation(null);
		setCancelNavigation(null);
	};

	const value = {
		openDialog,
		closeDialog,
	};

	return (
		<FormExitDialogContext.Provider value={value}>
			<Dialog open={!!confirmNavigation}>
				<DialogTitle
					sx={{
						width: 500,
					}}
				>
					Unsaved changes
					<IconButton
						onClick={cancelNavigation ? () => cancelNavigation(false) : () => {}}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent>Would you like to save changes?</DialogContent>

				<DialogActions>
					<Button variant="outlined" onClick={confirmNavigation ?? (() => {})}>
						Discard changes
					</Button>
					<Button
						variant="contained"
						onClick={cancelNavigation ? () => cancelNavigation(true) : () => {}}
					>
						Save changes
					</Button>
				</DialogActions>
			</Dialog>

			{children}
		</FormExitDialogContext.Provider>
	);
};
