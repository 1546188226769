
class Storage {

  PREFIX = 'kemtai_care_manage';
  ACCESS_TOKEN_KEY = 'accessToken';

  getStorageKey(key: string) {
    return `${this.PREFIX}_${key}`;
  }

  getItem(key: string) {
    return JSON.parse(window.localStorage.getItem(`${this.PREFIX}_${key}`) as string);
  }

  setItem(key: string, value: any) {
    window.localStorage.setItem(`${this.PREFIX}_${key}`, JSON.stringify(value));
    window.dispatchEvent(new Event('storage'));
  }

  clearItem(key: string) {
    window.localStorage.removeItem(`${this.PREFIX}_${key}`);
  }

  getAccessToken() {
    return this.getItem(this.ACCESS_TOKEN_KEY);
  }

  setAccessToken(token: string) {
    this.setItem(this.ACCESS_TOKEN_KEY, token);
  }

  clearAccessToken() {
    this.clearItem(this.ACCESS_TOKEN_KEY);
  }

  getRefreshToken() {
    return this.getItem("refreshToken");
  }

  setRefreshToken(token: string) {
    this.setItem("refreshToken", token);
  }

  clearRefreshToken() {
    this.clearItem("refreshToken");
  }

  clearOnLogout() {
    this.clearAccessToken();
    this.clearRefreshToken();
    this.clearItem("clinic");
  }
}

export const storage = new Storage();
