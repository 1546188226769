import { useState, useCallback } from 'react';


export const useDialog = () => {
  const [dialogsState, setDialogsState] = useState<{ [dialogName: string]: { isOpen: boolean, params?: any } }>({});

  const isOpen = useCallback((dialogName: string) => {
    return dialogsState[dialogName]?.isOpen ?? false;
  }, [dialogsState]);

  const openDialog = useCallback((dialogName: string, params?: any) => {
    setDialogsState(current => ({ ...current, [dialogName]: { isOpen: true, params } }));
  }, [dialogsState]);

  const closeDialog = useCallback((dialogName: string) => {
    setDialogsState(current => ({ ...current, [dialogName]: { isOpen: false } }));
  }, [dialogsState]);

  const dialogParams = useCallback((dialogName: string) => {
    return dialogsState[dialogName]?.params ?? {};
  }, [dialogsState]);

  return {
    isOpen,
    openDialog,
    closeDialog,
    dialogParams
  }
}
