import { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
	ConfirmationDialogContext,
	ConfirmationDialogOptions,
} from './ConfirmationDialogProvider';

export const useConfirmationDialog = () => {
	const ctx = useContext(ConfirmationDialogContext);

	const confirmation = async ({
		title,
		subtitle,
		confirmLabel,
		confirmType,
		declineLabel,
	}: ConfirmationDialogOptions) => {
		const confirm = await ctx.openDialog({
			title,
			subtitle,
			confirmLabel: confirmLabel ?? 'Confirm',
			confirmType: confirmType ?? 'default',
			declineLabel: declineLabel ?? 'Back',
		});

		return confirm;
	};

	return {
		confirmation,
	};
};
