import { jwtManageAxios } from 'libs/axios';
import { UserResponse } from '../types';


export type RefreshAccessTokenDTO = {
  refresh: string;
};

export const refreshAccessToken = (data: RefreshAccessTokenDTO): Promise<UserResponse> => {
  return jwtManageAxios.post(`auth/refresh-token/`, data);
};
