import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext, useLocation } from 'react-router-dom';

export function useNavigationBlocker(blocker: any, when = true): void {
	const navigator = useContext(UNSAFE_NavigationContext).navigator as any;
	const location = useLocation();

	useEffect(() => {
		if (!when) return;

		const unblock = navigator.block((tx: any) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when, location]);
}
