import { createTheme, ThemeProvider as MuiThemeProvider, darken, lighten, Palette, PaletteColor } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import GlobalStyles from './globalStyles';
import GilroyLightWoff from 'assets/fonts/Gilroy-Light.woff';
import GilroyExtraBoldWoff from 'assets/fonts/Gilroy-ExtraBold.woff';
import RedHatDisplayMediumTtf from 'assets/fonts/RedHatDisplay-Medium.ttf';
import RedHatDisplaySemiBoldTtf from 'assets/fonts/RedHatDisplay-SemiBold.ttf';
import RedHatDisplayBoldTtf from 'assets/fonts/RedHatDisplay-Bold.ttf';


const family = ["Red Hat Display", "Gilroy"].join(", ");

const { typography, palette, shadows, breakpoints } = createTheme({
  typography: { fontSize: 14 },
});

export const theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#F1F1F1",
      paper: "#fff",
    },
    primary: {
      main: "#1CC3AC",
      dark: "#606972",
      light: "#B5FFF6"
    },
    secondary: {
      main: "#000"
    },
    success: {
      main: '#1CC3AC',
      light: '#E9FBF8',
    },
    info: {
      main: "#CEE4F4",
      light: "#C0DCF0"
    },
    error: {
      main: "#FF634E",
      light: "#FFDEDA"
    },
    text: {
      primary: '#1B1C21',
      secondary: "#6B737A"
    },
    custom: {
      paper2: "#E6ECF1",
      paper3: "#F7F8F7",
      text: "#2E3139",
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          src: local('Gilroy'), url(${GilroyLightWoff}) format('woff');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Gilroy-'), url(${RedHatDisplayBoldTtf}) format('woff');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Gilroy-'), url(${RedHatDisplaySemiBoldTtf}) format('truetype');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Gilroy-'), url(${RedHatDisplayMediumTtf}) format('truetype');
        }

        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('Gilroy-'), url(${GilroyExtraBoldWoff}) format('woff');
        }
      `,
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          background: '#fff',
          borderBottomLeftRadius: '24px',
          borderBottomRightRadius: '24px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 60,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          [breakpoints.up("xs")]: {
            minWidth: 120,
          },
        },
        textColorInherit: {
          opacity: 1,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 60,
        },
        flexContainer: {
          height: "100%",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "rgba(0, 0, 0, 0.03)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
        },
        elevation2: {
          boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...typography.body1,
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 8,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          lineHeight: 1.3,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        size: "small"
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: 'white'
        },
        input: {
          fontWeight: 600,
          '&[type=number]': {
            mozAppearance: 'textfield',
          },
          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.custom?.paper3,
        })
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          boxShadow: 'none',
          borderRadius: '34px',
          fontWeight: theme.typography.fontWeightBold,
          fontSize: typography.pxToRem(14),
          background: 'transparent',
          textTransform: 'none',
          color: ownerState.color === "primary" ? theme.palette.text.primary : (theme.palette[(ownerState.color) as keyof Palette] as PaletteColor).main,
          borderColor: theme.palette.text.secondary,
          ...(
            ownerState.variant === 'contained' &&
            {
              backgroundColor: (theme.palette[(ownerState.color ?? "primary") as keyof Palette] as PaletteColor).main,
              color: '#F7FFFE',
              borderColor: 'transparent',
              '&:hover': {
                backgroundColor: darken((theme.palette[(ownerState.color ?? "primary") as keyof Palette] as PaletteColor).main, 0.1),
              },
            }
          ),
          ...(
            ownerState.variant === 'outlined' &&
            {
              borderColor: ownerState.color === "primary" ? '#C6CBCF' : (theme.palette[(ownerState.color) as keyof Palette] as PaletteColor).main,
            }
          ),
        }),
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          borderRadius: '8px',
          fontSize: '14px',
          fontWeight: 600,
        }),
        arrow: ({ theme }) => ({
          color: theme.palette.primary.main,
        })
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: ownerState.variant === "filled" ? theme.palette.common.white : theme.palette.common.black,
        })
      }
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: '16px'
        }
      }
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: family,
    h1: {
      fontFamily: family,
      fontWeight: 800,
      fontSize: typography.pxToRem(44),
      lineHeight: typography.pxToRem(43),
    },
    h2: {
      fontFamily: family,
      fontWeight: 700,
      fontSize: typography.pxToRem(24),
      lineHeight: typography.pxToRem(32),
    },
    h3: {
      fontFamily: family,
      fontWeight: 700,
      fontSize: typography.pxToRem(20),
      lineHeight: typography.pxToRem(28),
    },
    h4: {
      fontFamily: family,
      fontWeight: 700,
      fontSize: typography.pxToRem(18),
      lineHeight: typography.pxToRem(24),
    },
    h5: {
      fontFamily: family,
      fontWeight: 700,
      fontSize: typography.pxToRem(16),
      lineHeight: typography.pxToRem(20),
    },
    h6: {
      fontFamily: family,
      fontWeight: 700,
      fontSize: typography.pxToRem(14),
      lineHeight: typography.pxToRem(20),
    },
    subtitle1: {
      fontFamily: family,
      fontWeight: 500,
      opacity: 0.5,
      fontSize: typography.pxToRem(16),
      lineHeight: typography.pxToRem(19),
    },
    subtitle2: {
      fontFamily: family,
      fontWeight: 600,
      fontSize: typography.pxToRem(14),
      lineHeight: typography.pxToRem(19),
    },
    body1: {
      fontFamily: family,
      fontSize: "1rem",
      lineHeight: typography.pxToRem(21),
    },
    body2: {
      fontFamily: family,
      fontSize: typography.pxToRem(12),
      lineHeight: typography.pxToRem(20),
    },
    button: {
      fontFamily: family,
      fontSize: "1rem",
    },
    caption: {
      fontFamily: family,
      fontWeight: 500,
      fontSize: typography.pxToRem(12),
      lineHeight: typography.pxToRem(16),
    },
    overline: {
      fontFamily: family,
      fontSize: typography.pxToRem(12),
      fontWeight: 500,
      textTransform: "uppercase",
    },
  },
});


export const ThemeProvider: React.FC = ({ children }) => {

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />

      {children}
    </MuiThemeProvider>
  );

}
