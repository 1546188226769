import { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { FormExitDialogContext } from './FormExitDialogProvider';
import { useNavigationBlocker } from '@kemtai/hooks';

type HandleFormExitOptions = {
	isFormDirty: boolean;
	submitFunction: () => void;
};

export const useFormExitDialog = ({ isFormDirty, submitFunction }: HandleFormExitOptions) => {
	const ctx = useContext(FormExitDialogContext);

	const navigate = useNavigate();
	const location = useLocation();
	const [showPrompt, setShowPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState<any>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const cancelNavigation = useCallback((shouldSubmit = true) => {
		if (shouldSubmit) {
			submitFunction();
		}

		ctx.closeDialog();
	}, []);

	const confirmNavigation = useCallback(() => {
		ctx.closeDialog();
		setConfirmedNavigation(true);
	}, []);

	const handleBlockedNavigation = useCallback(
		(nextLocation) => {
			if (!confirmedNavigation) {
				if (nextLocation.location.pathname !== location.pathname) {
					ctx.openDialog({ cancelNavigation, confirmNavigation });
					setLastLocation(nextLocation);
				} else {
					nextLocation.retry();
					return true;
				}

				return false;
			}

			return true;
		},
		[confirmedNavigation]
	);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.location.pathname + lastLocation.location.search);
		}
	}, [confirmedNavigation, lastLocation]);

	useNavigationBlocker(handleBlockedNavigation, isFormDirty);
};
