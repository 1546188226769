import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import minMax from 'dayjs/plugin/minMax';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import UpdateLocale from 'dayjs/plugin/updateLocale';
import { default as legacyRouter } from '@kemtai/components/dist/router';
import * as Sentry from "@sentry/react";
import { ENVIRONMENT, SENTRY_DSN, VERSION } from 'config';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';


export const initApp = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: VERSION,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/[a-z]+-api.kemtai.com/, /^https:\/\/api.kemtai.com/],
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        networkDetailAllowUrls: [
          window.location.origin,
          /^https:\/\/[a-z-.]+.kemtai.com/,
        ]
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
  });
  
  
  legacyRouter.disable();
  dayjs.extend(weekOfYear);
  dayjs.extend(minMax);
  dayjs.extend(localeData);
  dayjs.extend(LocalizedFormat);
  dayjs.extend(UpdateLocale);
}
