import React, { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, useRoutes as reactRouterUseRoutes } from 'react-router-dom';
import { AuthLoader, useIsAuthenticated, useUser } from 'libs/auth';
import { AppLayout } from 'components';
import { Loader } from '@kemtai/ui';
import { ClinicProvider } from 'features/clinic';
import { wrapUseRoutes } from '@sentry/react';

const useRoutes = wrapUseRoutes(reactRouterUseRoutes);

const Home = lazy(() => import('features/home'));
const Auth = lazy(() => import('features/auth'));
const Patients = lazy(() => import('features/patients'));
const Exercises = lazy(() => import('features/exercises'));
const Sessions = lazy(() => import('features/sessions'));
const Staff = lazy(() => import('features/staff'));
const ProtocolTemplates = lazy(() => import('features/protocol-templates'));
const Inbox = lazy(() => import('features/inbox'));
const Embed = lazy(() => import('features/embed'));


export const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to="auth/login" />
  },
  {
    path: "/auth/*",
    element: <Auth />,
  },
  {
    path: "/embed/*",
    element: <>Loading</>
  },
  {
    path: "*",
    element: <Navigate to="/" />
  }
];


export const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/exercises/*",
        element: <Exercises />,
      },
      {
        path: "/sessions/*",
        element: <Sessions />,
      },
      {
        path: "/patients/*",
        element: <Patients />,
      },
      {
        path: "/staff/*",
        element: <Staff />,
      },
      {
        path: "/protocol-templates/*",
        element: <ProtocolTemplates />,
      },
      {
        path: "/inbox/*",
        element: <Inbox />,
      }
    ],
  },
  {
    path: "/embed/*",
    element: <Embed />
  },
  {
    path: "*",
    element: <Navigate to="/" />
  }
];


const Router = () => {
  const protectedElement = useRoutes(protectedRoutes) as JSX.Element;
	const publicElement = useRoutes(publicRoutes) as JSX.Element;

  return (
		<AuthLoader
			renderLoading={() => <Loader fullscreen title="Authenticating" />}
			renderUnauthenticated={() => publicElement}
		>
      <ClinicProvider>
			  {protectedElement}
      </ClinicProvider>
		</AuthLoader>
	);
};

export default Router;
