import React, { useState } from 'react';
import { AppBar as MuiAppBar, Container, Toolbar, Typography, Box, IconButton, Menu, MenuItem, Button, Tooltip, Avatar, Link, Badge } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import logoImage from 'assets/images/logo_dark.png';
import { useAuthorization } from 'libs/auth';
import { useNavigate, useMatch } from 'react-router-dom';
import { UserMenu } from './UserMenu';
import { ClinicSelect } from './ClinicSelect';
import { useUnreadChatMessages } from 'features/chat';
import { useAppLayout } from 'hooks';


type TabProps = {
  label: string|React.ReactNode;
  path: string;
};

const Tab: React.FC<TabProps> = ({ label, path }) => {

  const navigate = useNavigate();
  const match = useMatch(path);

  return (
    <Button
      onClick={() => navigate(path)}
      sx={{ 
        my: 2, 
        display: 'block'
      }}
    >
      <Typography
        variant="h6"
        color={match ? "text.primary" : "#8A9197"}
      >
        {label}
      </Typography>
    </Button>
  );

}


type AppBarProps = {
  disableBorderRadius?: boolean;
};

export const AppBar: React.FC<AppBarProps> = ({ children, disableBorderRadius = false }) => {

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const { checkAccess } = useAuthorization();

  const mainTabs = [
    { label: "Overview", path: "/" },
    { label: "Patients", path: "/patients",},
	].filter(Boolean);

  const secondaryTabs = [
    { label: "Protocol templates", path: "/protocol-templates" },
    { label: "Exercise library", path: "/exercises" },
		checkAccess({ resource: "staff", action: "list" }) && { label: "Staff", path: "/staff" }
	].filter(Boolean) as { label: string, path: string }[];

  const { data: unreadChatMessages } = useUnreadChatMessages();

  return (
    <MuiAppBar 
      position="sticky"
      sx={{
        ...(disableBorderRadius && {
          borderRadius: 0
        })
      }}
    >
      <Container 
        maxWidth={false}
      >
        <Toolbar 
          disableGutters 
          sx={{
            px: 1
          }}
        >
          <Link
            href="/"
            sx={{
              mr: 2,
              display: { 
                xs: 'none', 
                md: 'flex' 
              },
            }}
          >
            <img
              src={logoImage}
              alt=""
              height="11px"
            />
          </Link>
            
          <Box 
            sx={{ 
              flexGrow: 1, 
              display: { 
                xs: 'flex', 
                md: 'none' 
              } 
            }}
          >
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { 
                  xs: 'block', 
                  md: 'none' 
                },
              }}
            >
              {mainTabs.map((tab) => (
                <MenuItem key={tab.label} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{tab.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {mainTabs.map((tab) => 
              <Tab
                key={tab.label}
                label={tab.label}
                path={tab.path}
              />
            )}
          </Box>

          <Box 
            sx={{ 
              display: { 
                xs: 'none', 
                md: 'flex' 
              },
              mr: 3,
            }}
          >
            {secondaryTabs.map((tab) => 
              <Tab
                key={tab.label}
                label={tab.label}
                path={tab.path}
              />
            )}

            <Tab 
              label={
                <Badge 
                  badgeContent={unreadChatMessages?.length ?? 0} 
                  color="error"
                  sx={{
                    '& .MuiBadge-badge': {
                      color: 'white',
                      right: -5
                    }
                  }}
                >
                  Inbox
                </Badge>
              }
              path="inbox"
            />
          </Box>

          <Box 
            sx={{ 
              display: { 
                xs: 'none', 
                md: 'flex' 
              },
              mr: 3,
            }}
          >
            <ClinicSelect />
          </Box>
          
          <Box 
            sx={{ 
              flexGrow: 0 
            }}
          >
            <UserMenu />
          </Box>
        </Toolbar>
      </Container>

      {children}
    </MuiAppBar>
  );

}