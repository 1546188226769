import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircularProgress, Typography, ButtonProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, useFormExitDialog } from '../';
import { FormattedMessage } from 'react-intl';


enum SaveButtonState {
	Default = 0,
	Loading = 1,
	Success = 2,
	Error = 3,
}

type SaveButtonProps = {
	onSave: () => void;
	saveLabel?: string;
	isLoading?: boolean;
	isSuccess?: boolean;
	isDirty?: boolean;
	isError?: boolean;
};

export const SaveButton = ({
	onSave,
	saveLabel,
	isLoading,
	isSuccess,
	isDirty,
	isError,
	...props
}: SaveButtonProps & ButtonProps) => {
	const [saveButtonState, setSaveButtonState] = useState<SaveButtonState>(SaveButtonState.Default);

	useEffect(() => {
		if (isLoading) {
			setSaveButtonState(SaveButtonState.Loading);
		} else if (isSuccess) {
			setSaveButtonState(SaveButtonState.Success);

			setTimeout(() => {
				setSaveButtonState(SaveButtonState.Default);
			}, 2000);
		} else if (isError) {
			setSaveButtonState(SaveButtonState.Error);

			setTimeout(() => {
				setSaveButtonState(SaveButtonState.Default);
			}, 2000);
		} else {
			setSaveButtonState(SaveButtonState.Default);
		}
	}, [isLoading, isSuccess, isError]);

	useFormExitDialog({
		isFormDirty: (isDirty && !isLoading) ?? false,
		submitFunction: onSave,
	});

	return (
		<Button
			variant="contained"
			color={saveButtonState === SaveButtonState.Error ? 'error' : 'primary'}
			onClick={isLoading ? () => {} : onSave}
			disabled={isDirty === false}
			{...props}
		>
			<Typography
				sx={{
					opacity: saveButtonState === SaveButtonState.Default ? 1 : 0,
					transitionDuration: '300ms',
				}}
			>
				{saveLabel ?? 
					<FormattedMessage 
						defaultMessage="Save" id="TB4nnc3RQ9_TXaKYsKCRZ"
					/>
				}
			</Typography>

			<CircularProgress
				size={24}
				color="inherit"
				sx={{
					position: 'absolute',
					opacity: saveButtonState === SaveButtonState.Loading ? 1 : 0,
					transitionDuration: '300ms',
				}}
			/>

			<CheckIcon
				sx={{
					position: 'absolute',
					opacity: saveButtonState === SaveButtonState.Success ? 1 : 0,
					transitionDuration: '300ms',
				}}
			/>

			<ErrorOutlineIcon
				sx={{
					position: 'absolute',
					opacity: saveButtonState === SaveButtonState.Error ? 1 : 0,
					transitionDuration: '300ms',
				}}
			/>
		</Button>
	);
};
