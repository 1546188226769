import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputAdornment, TextFieldProps, TextField, OutlinedInput, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


type FormInputProps = {
  name: string;
  control: any;
  label?: string;
  setValue?: any;
  disabled?: boolean;
  type?: string;
  defaultValue?: any;
  inputProps?: any;
  multiline?: true;
  rows?: number;
  isSearch?: boolean;
  minimal?: true;
} & TextFieldProps;


export const FormInputText = React.forwardRef<HTMLInputElement, FormInputProps>(({ name, control, label, disabled, type, defaultValue, isSearch, minimal, ...props }, ref) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          ref={ref}
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={(event) =>
            onChange(
              event.target.value
                ? type === 'number'
                  ? +event.target.value
                  : event.target.value
                : null
            )
          }
          value={value ?? ""}
          defaultValue={defaultValue}
          fullWidth
          label={isSearch ? undefined : label}
          placeholder={isSearch ? label : undefined}
          disabled={!!disabled}
          type={type ?? "text"}
          {...props}
          {...(isSearch && {
            sx: {
              '& .MuiOutlinedInput-root': {
                borderRadius: '40px',
              }
            },
            InputProps: {
              startAdornment: (
                <InputAdornment 
                  position="start"
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }
          })}
        />
      )}
    />
  );
});
