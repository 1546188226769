import React from 'react';
import { TextField as MuiTextField, TextFieldProps, OutlinedInputProps } from '@mui/material';
import { alpha, styled } from '@mui/material';

export const TextField = styled((props: TextFieldProps) => (
  <MuiTextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    variant="filled"
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:before': {
      content: 'none'
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
