import { Box, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


const Rule: React.FC<{ title: string, isValid: boolean }> = ({ title, isValid }) => {

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0.5}
      ml={0.5}
    >
      {isValid
        ? <CheckCircleIcon 
            color="success"
            sx={{
              fontSize: '16px'
            }}
          />
        : <CancelIcon 
            color="error"
            sx={{
              fontSize: '16px'
            }}
          />
      }

      <Typography
        variant="caption"
        color={isValid ? "success.main" : "error.main"}
      >
        {title}
      </Typography>
    </Box>
  );

}


type PasswordStrengthProps = {
  field?: string;
};

export const PasswordStrength: React.FC<PasswordStrengthProps> = ({ field }) => {

  const { watch } = useFormContext();
  
  const password = watch(field ?? "password");
  
  const isMinimumChars = password?.length >= 8;
  const hasLowercase = password?.match(/[a-z]/);
  const hasUppercase = password?.match(/[A-Z]/);
  const hasNumber = password?.match(/\d/);
  const hasSymbol = password?.match(/[!@#%^$&*\(\)_\+\-=\[\]{}|;':\",./<>?~`]/);

  if (!password) {
    return null;
  }

  return (
    <Box>
      <Typography
        variant="h6"
        mt={1}
        mb={0.5}
      >
        Password must contain:
      </Typography>

      <Rule 
        title="Minimum 8 characters"
        isValid={isMinimumChars}
      />

      <Rule 
        title="At least 1 lowercase letter"
        isValid={hasLowercase}
      />

      <Rule 
        title="At least 1 uppercase letter"
        isValid={hasUppercase}
      />

      <Rule 
        title="At least 1 number"
        isValid={hasNumber}
      />

      <Rule 
        title="At least 1 special symbol (e.g. !@#$%^&*)"
        isValid={hasSymbol}
      />
    </Box>
  );

}