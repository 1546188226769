import { Grid, CircularProgress, Typography, GridProps } from '@mui/material';
import React from 'react';


type LoaderProps = {
	fullscreen?: true
	title?: string;
} & GridProps;

export const Loader: React.FC<LoaderProps> = ({ fullscreen, title, ...props }) => {
	return (
		<Grid
			container
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
			sx={fullscreen ? { width: '100vw', height: '100vh' } : { width: '100%', height: '100%' }}
			data-testid="loading"
			{...props}
		>
			<CircularProgress />

			{title &&
				<Typography
					mt={1}
					variant="h5"
				>
					{title}
				</Typography>
			}
		</Grid>
	);
};
