import { useSearchParams, useLocation } from 'react-router-dom';

type UseActionDialog = [(action: string, params?: object) => void, () => void];

export function useActionDialog(): UseActionDialog {
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();

	const close = () => {
		searchParams.delete('action');
		searchParams.delete('id');
		searchParams.delete('ids');

		setSearchParams(searchParams, { replace: true, state: location.state });
	};

	const open = (action: string, params: object = {}) => {
		setSearchParams({
			action,
			...params,
			...Object.fromEntries(searchParams),
		}, { replace: true, state: location.state });
	};

	return [open, close];
}
