import { Grid, Typography, Menu, MenuItem, Avatar, IconButton } from '@mui/material';
import { Button } from '@kemtai/ui';
import { useAnchorElement, useDialog } from '@kemtai/hooks';
import { IUser } from 'features/auth';
import { useUser, useLogout } from 'libs/auth';
import { UserAvatar } from 'components/UserAvatar';
import { SettingsDialog } from 'features/settings';
import { useCurrentStaff } from 'features/staff';

export const UserMenu = () => {

  const user = useUser();
  const logout = useLogout();
  const { data: currentStaff } = useCurrentStaff();

  const [anchorEl, onClick, handleClose] = useAnchorElement<HTMLButtonElement>();
  const { openDialog, isOpen, closeDialog } = useDialog();

  const handleSettingsClick = () => {
    handleClose();
    openDialog("settings");
  }

  return (
    <>
      <IconButton
        onClick={onClick}
        data-testid="userMenu"
      >
        <UserAvatar
          label={user.data?.first_name?.slice(0,1)}
          picture={user.data?.picture}
          size={34}
        />
      </IconButton>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          "& .MuiPaper-root": {
            minWidth: 180,
          }
        }}
      >
        <MenuItem
          onClick={handleSettingsClick}
          data-testid="settings-button"
        >
          Settings
        </MenuItem>

        <MenuItem
          onClick={() => logout.mutate({})}
          data-testid="logout-button"
        >
          Logout
        </MenuItem>
      </Menu>

      {currentStaff &&
        <SettingsDialog
          staff={currentStaff}
          open={isOpen("settings")}
          onClose={() => closeDialog("settings")}
        />
      }
    </>
  );

}
