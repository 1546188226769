import { IntlProvider } from "react-intl";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProviderProps } from "@mui/lab";
import { useEffect, useState } from "react";
import dayjs from "dayjs";


export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_FORMAT = () => dayjs.localeData().longDateFormat('ll').replace(/,?\s*YYYY/g, "");

export enum Locale {
  EN = "en",
  HE = "he",
};

export const defaultLocale = Locale.EN;

const DAYJS_LOCALES = {
  [Locale.EN]: import("dayjs/locale/en"),
  // [Locale.HE]: import("dayjs/locale/he"),
};

const DATE_PICKER_LOCALES_IMPORTS = {
  [Locale.EN]: async () => { const { enUS } = await import("@mui/x-date-pickers/locales/enUS"); return enUS; },
  // [Locale.HE]: async () => { const { heIL } = await import("@mui/x-date-pickers/locales/heIL"); return heIL; },
};


export const LocaleProvider: React.FC = ({ children }) => {

  const locale = defaultLocale;
  const [datePickerLocaleText, setDatePickerLocaleText] = useState<LocalizationProviderProps['localeText']|undefined>(undefined);

  useEffect(() => {
    async function changeLocale() {
      const localteData = await DAYJS_LOCALES[locale];
      dayjs.locale(locale);

      const _datePicketLocaleText = await DATE_PICKER_LOCALES_IMPORTS[locale]();
      setDatePickerLocaleText(_datePicketLocaleText.components.MuiLocalizationProvider.defaultProps.localeText);
    }

    changeLocale();
  }, [locale]);

  return (
    <IntlProvider 
      messages={{}} 
      locale={locale}
      defaultLocale={defaultLocale}
    >
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={locale}
        localeText={datePickerLocaleText}
      >
       {children}
      </LocalizationProvider>
    </IntlProvider>
  );

}