export const API_URL = process.env.REACT_APP_API_URL as string;
export const VERSION = process.env.REACT_APP_VERSION as string;
export const ENVIRONMENT = process.env.REACT_APP_ENV as string;
export const PROJECT_KEY = process.env.REACT_APP_PROJECT_KEY as string;
export const ENVIRONMENT_KEY = process.env.REACT_APP_ENVIRONMENT_KEY as string;
export const LOGROCKET_ID = process.env.REACT_APP_LOGROCKET_ID as string;
export const AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY as string;
export const AWS_SECRET = process.env.REACT_APP_AWS_SECRET as string;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION as string;
export const AWS_VIDEOS_BUCKET_NAME = process.env.REACT_APP_AWS_VIDEOS_BUCKET_NAME as string;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;