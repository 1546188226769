import React, { Suspense, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { AppLayoutProvider } from './AppLayoutProvider';
import { useIsFetching } from '@tanstack/react-query';
import { CircularProgress, LinearProgress } from '@mui/material';
import { TermsOfUseDialog } from 'components';
import { Loader } from '@kemtai/ui';
import { useCurrentStaff } from 'features/staff';
import { useDialog } from '@kemtai/hooks';
import { AppBar } from '../AppBar';
import { updateUserLogin } from 'features/auth';


const CONTENT_WIDTH = 1350;

export const AppLayout = () => {
  
  const headerLeadRef = React.useRef<HTMLDivElement>(null);
  const headerTitleRef = React.useRef<HTMLDivElement>(null);
  const headerActionRef = React.useRef<HTMLDivElement>(null);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const footerRef = React.useRef<HTMLDivElement>(null);

	const { openDialog, closeDialog, isOpen } = useDialog();

  const isFetching = useIsFetching();

	const { data: staff } = useCurrentStaff();

	useEffect(() => {
		if (staff?.is_waiver_approved === false) {
			openDialog("termsofuse");
		}

    updateUserLogin();
	}, [staff]);

	const navigate = useNavigate();

	const navigateHome = () => {
		navigate('/', { replace: true });
	};

  const disableBorderRadius = headerRef.current?.children?.length !== 0 ||
                              headerLeadRef.current?.children?.length !== 0 ||
                              headerTitleRef.current?.children?.length !== 0 ||
                              headerActionRef.current?.children?.length !== 0;

  return (
    <>
      <AppBar 
        disableBorderRadius={disableBorderRadius}
      />

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          right: 0
        }}
      >
        {isFetching > 0 &&
          <LinearProgress />
        }
      </Box>

      <Box
        component="main"
        sx={{
          pb: 4,
        }}
      >
        <Box
          bgcolor="background.paper"
          sx={{
            borderBottomLeftRadius: '24px',
            borderBottomRightRadius: '24px',
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            alignItems="center"
            px={4}
          >
            <div ref={headerLeadRef} style={{ justifySelf: 'flex-start'}} />
            <div ref={headerTitleRef} />
            <div ref={headerActionRef} style={{ justifySelf: 'flex-end'}} />
          </Box>

          <div ref={headerRef} />
        </Box>

        <AppLayoutProvider context={{ headerRef, headerLeadRef, headerTitleRef, headerActionRef, footerRef }}>
          <Suspense 
            fallback={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="500px"
              >
                <CircularProgress />
              </Box>
            }
          >
            <Outlet />
          </Suspense>
        </AppLayoutProvider>
      </Box>

      <Box
        ref={footerRef}
        sx={{
          position: 'sticky',
          bottom: 0,
          zIndex: 10
        }}
      />

			<TermsOfUseDialog
				standalone={staff?.is_waiver_approved !== false}
				open={isOpen("termsofuse")}
				onClose={() => closeDialog("termsofuse")}
			/>
    </>
  );
}
