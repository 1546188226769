import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { SettingsForm } from "../SettingsForm";
import { SaveButton } from "@kemtai/ui";
import { useNotificationStore } from "stores";
import { zodResolver } from "@hookform/resolvers/zod";
import { IStaff, UpdateCurrentStaffDTO, useUpdateCurrentStaff } from "features/staff";
import { z } from "zod";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const schema = z.object({
  first_name: z.string().optional().nullable(),
  last_name: z.string().optional().nullable(),
  metadata: z.object({
    preferences: z.object({
      lang: z.string(),
    })
  })
});

type SettingsDialogProps = {
  staff: IStaff;
  open: boolean;
  onClose: () => void;
};

export const SettingsDialog: React.FC<SettingsDialogProps> = ({ staff, open, onClose }) => {

  const { formatMessage } = useIntl();

  const methods = useForm<UpdateCurrentStaffDTO['data']>({
    defaultValues: {
      ...staff,
      metadata: {
        ...staff.metadata,
        preferences: {
          ...staff.metadata.preferences,
          lang: staff.metadata?.preferences?.lang ?? "en"
        }
      }
    },
    resolver: zodResolver(schema),
  });
  
  const { handleSubmit, reset, formState } = methods;

  const updateCurrentStaffMutation = useUpdateCurrentStaff();

  const { addNotification } = useNotificationStore();

  const onSubmit = async (data: UpdateCurrentStaffDTO['data']) => {
    const result = await updateCurrentStaffMutation.mutateAsync({
      data
    });

    reset(result);

    addNotification({
      type: 'success',
      title: formatMessage({ defaultMessage: 'Settings updated', id: 'QAtJOKiVr5m5Itt3Zib0O' })
    });

    onClose();
  }

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        Settings

        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          width: '400px'
        }} 
      >
        <FormProvider {...methods}>
          <SettingsForm />
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <SaveButton
          onSave={handleSubmit(onSubmit)}
          saveLabel="Save"
          isLoading={updateCurrentStaffMutation.isLoading}
          isSuccess={updateCurrentStaffMutation.isSuccess}
          isError={Object.keys(formState.errors).length > 0}
        />
      </DialogActions>
    </Dialog>
  );

}