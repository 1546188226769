import React, { useContext, useEffect } from 'react';

import { useLocalStorage } from 'hooks';
import { useClinics, IClinic } from 'features/clinic';
import { useIsAuthenticated, useUser } from 'libs/auth';
import { useQueryClient } from 'libs/react-query';
import { Loader } from '@kemtai/ui';
import { storage } from 'libs/storage';


type ClinicContextValue = {
	clinic: IClinic|null;
	setClinic: (clinicId: string) => void;
};

export const ClinicContext = React.createContext<ClinicContextValue>({} as ClinicContextValue);

export const ClinicProvider: React.FC = ({ children }) => {

	const queryClient = useQueryClient();

	const user = useUser();
	
	const { isLoading, data: clinics, isFetching } = useClinics({
		config: {
			enabled: !!user?.data
		}
	});

	const { value: clinicId, setValue: setClinicId } = useLocalStorage('clinicId', undefined);
	const { value: tenantKey, setValue: setTenantKey } = useLocalStorage('tenantKey', undefined);

  const clinic = clinics?.find(clinic => clinicId ? clinic.id === clinicId : true) ?? null;

	const setClinic = (clinicId: string) => {
		const clinic = clinics?.find(clinic => clinic.id === clinicId);
		setClinicId(clinic!.id);
		setTenantKey(clinic!.tenant_key);
		queryClient.invalidateQueries();
	}

	useEffect(() => {
		if (!clinicId && clinics && clinics[0].id) {
			setClinicId(clinics[0].id);
			setTenantKey(clinics[0].tenant_key);
		} else if (clinicId && clinics && !clinic) {
			setClinicId(clinics[0].id);
			setTenantKey(clinics[0].tenant_key);
		}
	}, [clinics, clinicId, clinic]);

	return (
		<ClinicContext.Provider
			value={{
				clinic,
				setClinic,
			}}
		>
			{!clinicId
				? <Loader 
						fullscreen 
						title="Loading clinic data" 
					/>
				: children
			}
		</ClinicContext.Provider>
	);
};
