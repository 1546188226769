import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormInputText, FormSelect } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDialog } from '@kemtai/hooks';
import { ChangePasswordDTO, useChangePassword } from 'features/auth';
import { PasswordStrength } from 'features/auth/components/PasswordStrength';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';



const schema = z.object({
  current_password: z.string(),
  new_password: z.string(),
  confirm_new_password: z.string(),
}).superRefine(({ confirm_new_password, new_password }, ctx) => {
  if (new_password.length < 8) {
    ctx.addIssue({
      code: "custom",
      message: "Minimum 8 characters",
      path: ['isMinimumChars']
    });
  }

  if (!new_password.match(/[a-z]/)) {
    ctx.addIssue({
      code: "custom",
      message: "At least 1 lowercase letter",
      path: ['hasLowercase']
    });
  }

  if (!new_password.match(/[A-Z]/)) {
    ctx.addIssue({
      code: "custom",
      message: "At least 1 uppercase letter",
      path: ['hasUppercase']
    });
  }

  if (!new_password.match(/\d/)) {
    ctx.addIssue({
      code: "custom",
      message: "At least 1 number",
      path: ['hasNumber']
    });
  }

  if (!new_password.match(/[!@#%^$&*\(\)_\+\-=\[\]{}|;':\",./<>?~`]/)) {
    ctx.addIssue({
      code: "custom",
      message: "At least 1 special symbol (e.g. !@#$%^&*)",
      path: ['hasSymbol']
    });
  }

  if (confirm_new_password !== new_password) {
    ctx.addIssue({
      code: "custom",
      message: "The passwords did not match",
      path: ['confirm_new_password']
    });
  }
});


type PasswordSettingsFormProps = {

};

export const PasswordSettingsForm: React.FC<PasswordSettingsFormProps> = () => {

  const methods = useForm<ChangePasswordDTO['data']>({
    resolver: zodResolver(schema),
  });
  
  const { control, handleSubmit, reset } = methods;
  
  const { isOpen, openDialog, closeDialog } = useDialog();

  const handleCancelClick = () => {
    closeDialog("change-password");
    reset();
  }

  const changePasswordMutation = useChangePassword();

  const onSubmit = async (data: ChangePasswordDTO['data']) => {
    await changePasswordMutation.mutateAsync({ data });
    closeDialog("change-password");
    reset();
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      my={2}
    >
      <Typography
        variant="h4"
      >
        <FormattedMessage
          defaultMessage="Password" id="CLH5ItOKAOzHftCuoXwOq"
        />
      </Typography>

      <Button
        size="small"
        sx={{
          textDecoration: 'underline'
        }}
        onClick={() => openDialog("change-password")}
      >
        Change password
      </Button>

      <Dialog
        open={isOpen("change-password")}
      >
        <DialogTitle>
          Change password
        </DialogTitle>

        <FormProvider {...methods}>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '400px',
              height: '350px'
            }}
          >
            <FormInputText 
              label="Current password"
              name="current_password"
              type="password"
              control={control}
              sx={{
                mt: 2
              }}
            />

            <Box>
              <FormInputText 
                label="New password"
                name="new_password"
                type="password"
                control={control}
              />

              <PasswordStrength 
                field="new_password"
              />
            </Box>
            
            <FormInputText 
              label="Confirm new password"
              name="confirm_new_password"
              type="password"
              control={control}
            />
          </DialogContent>
        </FormProvider>
        
        <DialogActions>
          <Button
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Change password
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

}
