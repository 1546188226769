import React from 'react';
import { Autocomplete as MuiAutocomplete, AutocompleteProps } from '@mui/material';
import { alpha, styled } from '@mui/material';

export const Autocomplete = styled((props: AutocompleteProps<"autocomplete", undefined, undefined, undefined>) => (
	<MuiAutocomplete {...props} />
))(({ theme }) => ({
	'& .MuiInputBase-root': {
		'&:before': {
			display: 'none'
		}
	},
}));
