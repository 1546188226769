import React from 'react';
import {
  CircularProgress,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  ButtonTypeMap as MuiButtonTypeMap,
} from '@mui/material';


interface ButtonInnerProps {
  isLoading?: boolean;
}

export type ButtonProps<T extends React.ElementType = "button"> = MuiButtonProps<T> & ButtonInnerProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ isLoading, startIcon, onClick, disabled, ...props }, ref) => {

  return (
    <MuiButton
      ref={ref}
      disableRipple
      disabled={disabled || isLoading}
      onClick={onClick}
      {...props}
      {...(props.type === "submit" ? { 'data-testid': 'submit' } : {})}
      startIcon={isLoading ? <CircularProgress size={20} /> : startIcon}
    >
      {props.children}
    </MuiButton>
  );
});
