import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';


type ErrorFallbackProps = {
  eventId: string;
};

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ eventId }) => {

  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={3}
    >
      <ErrorIcon
        sx={{
          fontSize: 200,
          color: 'error',
        }}
      />

      <Box>
        <Typography variant="h4" align="center">
          We've encountered an unexpected error
        </Typography>

        <Typography align="center">
          Try to refresh the page or navigate home.
        </Typography>

        <Typography
          variant="caption"
        >
          Error ID: {eventId}
        </Typography>
      </Box>

      <Box display="flex" gap={2}>
        <Button variant="contained" onClick={() => navigate("/")}>
          Go Home
        </Button>

        <Button variant="contained" onClick={() => window.location.reload()}>
          Refresh
        </Button>
      </Box>
    </Box>
  );

}