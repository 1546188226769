import  ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Card,
	CardHeader,
	CardContent,
	CardHeaderProps,
	CardContentProps,
	IconButton,
	Collapse,
	Box,
} from '@mui/material';
import React, { useState } from 'react';

type ContentBlockProps = {
	CardHeaderProps?: CardHeaderProps;
	CardContentProps?: CardContentProps;
	collapsable?: true;
	collapsed?: boolean;
};

export const ContentBlock: React.FC<ContentBlockProps> = ({
	CardHeaderProps,
	CardContentProps,
	collapsable,
	collapsed,
	children,
}) => {
	const [expanded, setExpanded] = useState(collapsed !== undefined ? !collapsed : true);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Card elevation={0}>
			<CardHeader
				{...CardHeaderProps}
				title={
					<Box display="flex" alignItems="center" gap={1}>
						{CardHeaderProps?.title}

						{collapsable && (
							<IconButton
								onClick={handleExpandClick}
								size="small"
								sx={{
									transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
									transition: 'transform 0.3s ease',
								}}
							>
								<ExpandMoreIcon
									fontSize="inherit"
									sx={{
										border: '1px solid rgba(255, 255, 255, 0.3)',
										borderRadius: '50%',
									}}
								/>
							</IconButton>
						)}
					</Box>
				}
			/>

			<Collapse in={!collapsable || expanded} timeout="auto" mountOnEnter>
				<CardContent {...CardContentProps}>{children}</CardContent>
			</Collapse>
		</Card>
	);
};
