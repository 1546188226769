import React, { useEffect, useRef } from 'react';
import LoggingService from '@kemtai/logger';
import { useLocation } from 'react-router-dom';
import { useUser } from 'libs/auth';
import LogRocket from 'logrocket';
import { LOGROCKET_ID } from 'config';
import { IUser } from 'features/auth';


type LogOptions = {
  event: string;
  data?: object;
};

type LoggerContextValue = {
  log: (options: LogOptions) => void;
  identify: (user: IUser) => void;
};

export const LoggerContext = React.createContext<LoggerContextValue>({} as LoggerContextValue);

type LoggerProviderProps = {
  app: string;
  apiUrl?: string;
};

export const LoggerProvider: React.FC<LoggerProviderProps> = ({ app, apiUrl, children }) => {

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      LoggingService.init({
        app: app,
        url: apiUrl ?? "https://api.kemtai.com/stable/log/"
      });

      LogRocket.init(LOGROCKET_ID, {
        network: {
          requestSanitizer: request => {
            if (request.url.toLowerCase().indexOf('signup') !== -1) {
              delete request.body;
            }
            
            return request;
          },
        },
      });
    }
  }, []);

  const log = ({ event, data }: LogOptions) => {
    LoggingService.event(event, data);
  }

  const identify = (user: IUser) => {
    LoggingService.setContext("user_email", user.id);

    log({
     event: "user",
     data: {
       id: user.id,
       email: user.email
     }
    });
  }

  const user = useUser({
    onSuccess: (user) => {
      if (user) {
        identify(user);

        LogRocket.identify(user.id, {
          name: `${user.first_name || ""} ${user.last_name || ""}`,
          email: user.email,
        });
      }
    }
  });

  const lastLocation = useRef(window.location.href);
  const location = useLocation();

  useEffect(() => {
    if (lastLocation.current !== window.location.href) {
      log({
        event: "URLChange",
        data: {
          oldURL: lastLocation.current,
          newURL: window.location.href,
        }
      });

      lastLocation.current = window.location.href;
    }
  }, [location]);

	return (
		<LoggerContext.Provider value={{ log, identify }}>
		  {children}
		</LoggerContext.Provider>
	);
};
