import { IStaffInvitation } from '../types';
import { axios } from 'libs/axios';
import { useQuery, QueryConfig, useMutation, MutationConfig } from 'libs/react-query';
import { useNotificationStore } from 'stores';

export type ChangePasswordDTO = {
  data: {
    current_password: string;
    new_password: string;
  }
};

export const changePassword = ({ data }: ChangePasswordDTO): Promise<void> => {
  return axios.post(`auth/change-password/`, data);
};

type UseChangePasswordOptions = {
	config?: MutationConfig<typeof changePassword>;
};

export const useChangePassword = ({ config }: UseChangePasswordOptions = {}) => {
  const { addNotification } = useNotificationStore();

	return useMutation({
		mutationFn: changePassword,
    onError: (error, __, context: any) => {
      if (error.response?.data?.current_password?.[0] === "wrong_password") {
        addNotification({
          type: "error",
          title: "Validation error",
          message: "The given password is incorrect"
        });
      }
		},
		onSuccess: (result, variables, context: any) => {
			addNotification({
				type: 'success',
				title: 'Password changed',
			});
		},
		...config,
	});
};
