import React, { useState, useEffect } from 'react';
import {
  Column,
  Table as ReactTable,
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  OnChangeFn,
  flexRender,
  SortingState,
  Row,
  RowSelectionState,
  Updater,
} from '@tanstack/react-table';
import { Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TablePagination, Box, IconButton, Typography, useTheme } from '@mui/material';

import LastPageIcon  from '@mui/icons-material/LastPage';
import KeyboardArrowRightIcon  from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon  from '@mui/icons-material/KeyboardArrowLeft';
import FirstPageIcon  from '@mui/icons-material/FirstPage';
import ArrowUpwardIcon  from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon  from '@mui/icons-material/ArrowDownward';



import { Loader } from '../Loader';


interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        data-testid="datagrid-first-page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        data-testid="datagrid-previous-page"
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        data-testid="datagrid-next-page"
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        data-testid="datagrid-last-page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

type DataGridProps = {
  data: any[];
  isLoading?: boolean;
  count?: number;
  columns: ColumnDef<any>[];
  pageIndex?: number;
  pageSize?: number;
  sorting?: SortingState,
  onSortingChange?: (sorting: Updater<SortingState>) => void;
  onPageIndexChange?: (pageIndex: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  onRowClick?: (data: any) => void;
};

export const DataGrid: React.FC<DataGridProps> = ({ data, isLoading, count, columns, pageIndex, pageSize, onPageIndexChange, onPageSizeChange, sorting, onSortingChange, onRowClick }) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    onPageIndexChange?.(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onPageSizeChange?.(Number(event.target.value));
    onPageIndexChange?.(0);
  };

  const [rowSelection, setRowSelection] = React.useState({})

  useEffect(() => {
    if (Object.keys(rowSelection).length === 1) {
      onRowClick?.(data[parseInt(Object.keys(rowSelection)[0])]);
    }
  }, [rowSelection]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    manualPagination: true,
    manualSorting: true,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    onSortingChange: onSortingChange,
    getCoreRowModel: getCoreRowModel(),
  });

  const theme = useTheme();

  return (
    <Table>
      {table.getHeaderGroups().map(headerGroup => (
        <TableHead>
          {headerGroup.headers.map(header => (
            <TableCell
              key={header.id}
              sx={{
                cursor: 'pointer',
              }}
            >
              {header.isPlaceholder
                ? null
                : <Box
                    display="flex"
                    gap={1}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}

                    {{
                      asc: <ArrowUpwardIcon />,
                      desc: <ArrowDownwardIcon />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </Box>
                }
            </TableCell>
          ))}
        </TableHead>
      ))}

      <TableBody data-testid="datagrid-rows">
        {table.getRowModel().rows.length === 0 &&
          <TableRow
            sx={{
              backgroundColor: theme.palette.grey['50']
            }}
          >
            <TableCell
              colSpan={columns.length}
              align="center"
            >
              <Typography
                variant="body2"
              >
                {isLoading
                  ? <Loader />
                  : "No data"
                }
              </Typography>
            </TableCell>
          </TableRow>
        }

        {table.getRowModel().rows.map(row => (
          <TableRow
            key={row.id}
            hover
            onClick={row.getToggleSelectedHandler()}
            selected={row.getIsSelected()}
            sx={{
              cursor: 'pointer',
            }}
          >
            {row.getVisibleCells().map(cell => (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>

      <TableFooter>
        <TableRow>
          {pageIndex !== undefined && count !== undefined && pageSize !== undefined &&
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={count}
              rowsPerPage={pageSize}
              page={pageIndex}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          }
        </TableRow>
      </TableFooter>
    </Table>
  );

}
