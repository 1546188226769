import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { string } from "zod";


type UserAvatarProps = {
  picture?: string|null, 
  label?: string|null, 
  size: number;
};

export const UserAvatar: React.FC<UserAvatarProps> = ({ label, picture, size }) => {

  if (picture) {
    return (
      <img
        src={picture}
        alt=""
        height={size}
        width={size}
        style={{
          borderRadius: '50%'
        }}
      />
    );
  } else if (label) {
    return (
      <Avatar
        sx={{
          height: size,
          width: size,
        }}  
      >
        <Typography
          variant="body1"
          sx={{
            textTransform: 'uppercase'
          }}
        >
          {label}
        </Typography>
      </Avatar>
    );
  } else {
    return (
      <Box 
        sx={{
          height: size,
          width: size,
          bgcolor: 'background.default',
          borderRadius: '50%'
        }}
      />
    );
  }

}