import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormInputText } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';


type PersonalInfoSettingsFormProps = {

};

export const PersonalInfoSettingsForm: React.FC<PersonalInfoSettingsFormProps> = () => {

  const { formatMessage } = useIntl();
  const { control, formState } = useFormContext();

  return (
    <Box>
      <Typography
        variant="h4"
      >
        <FormattedMessage 
          defaultMessage="Personal info" id="1TxoSYlHyU0fw_eeJO5Kn"
        />
      </Typography>

      <Box
        display="grid"
        gap={1}
        my={2}
        mx={2}
      >
        <FormInputText
          name="first_name"
          label={formatMessage({ defaultMessage: "First name", id: 'YbEj67VW2kcHDNWXvRTH-' })}
          control={control}
          data-testid="first-name-input"
        />

        <FormInputText
          name="last_name"
          label={formatMessage({ defaultMessage: "Last name", id: 'XNw5WngCsVDGEnbuZb9Rv' })}
          control={control}
          data-testid="last-name-input"
        />
      </Box>
    </Box>
  );

}
