import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormSelect } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';


const LANGUAGE_OPTIONS = [
  { value: "en", label: "English" },
  { value: "he", label: "Hebrew" },
];

type LanguageSettingsFormProps = {

};

export const LanguageSettingsForm: React.FC<LanguageSettingsFormProps> = () => {

  const { formatMessage } = useIntl();
  const { control, formState, watch } = useFormContext();

  return (
    <Box>
      <Typography
        variant="h4"
      >
        <FormattedMessage
          defaultMessage="Language" id="ouH2VVUyh83Gxf9Wu_aYZ"
        />
      </Typography>

      <Box
        display="grid"
        gap={1}
        my={2}
        mx={2}
      >
        <FormSelect
          name="metadata.preferences.lang"
          label={formatMessage({ defaultMessage: "Language", id: '-ej3whyvsq320etVahMkJ' })}
          options={LANGUAGE_OPTIONS}
          control={control}
          data-testid="language-select"
        />
      </Box>
    </Box>
  );

}
