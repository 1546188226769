import React from 'react';
import Router from './routes';
import { Notifications } from 'components';
import { AppProvider } from './provider';
import { initApp } from './initApp';


initApp();

const App = () => {

  return (
    <AppProvider>
      <Notifications />

      <Router />
    </AppProvider>
  );

};

export default App;
