import React from 'react';
import { Select as MuiSelect, SelectProps, OutlinedInputProps } from '@mui/material';
import { alpha, styled } from '@mui/material';

export const Select = styled((props: SelectProps) => (
	<MuiSelect size="small" variant="filled" {...props} />
))(({ theme }) => ({
	'&.MuiInputBase-root': {
		'&:before': {
			display: 'none'
		}
	},
	'& .MuiInputBase-input': {
		border: '1px solid #e2e2e1',
		overflow: 'hidden',
		borderRadius: 4,
		backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
		transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&.Mui-focused': {
			backgroundColor: 'transparent',
			boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
			borderColor: theme.palette.primary.main,
		},
	},
}));
